import 'fontsource-roboto';
import 'firebase/analytics';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import React from 'react';

import Loader from './components/Loader';

const Router = React.lazy(() => import('./Router'));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6200ee'
    }
  }
});

// TODO: use environment variables
firebase.initializeApp({
  apiKey: 'AIzaSyDNjNbRsAHpMFen4Weh1qKuidsGjKjogno',
  authDomain: 'scrum-room-ecc2f.firebaseapp.com',
  projectId: 'scrum-room-ecc2f',
  storageBucket: 'scrum-room-ecc2f.appspot.com',
  messagingSenderId: '533080959510',
  appId: '1:533080959510:web:9fa86207870c3440fb6337',
  measurementId: 'G-7JVB7MMPTM'
});

firebase.analytics();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Loader /> }>
        <Router />
      </React.Suspense>
    </ThemeProvider>
  );
};

export default App;

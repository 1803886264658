import './Loader.css';

import React from 'react';

import logo from '../../logo.svg';

const Loader: React.FC = () => {
  return (
    <div className="overlay">
      <img src={logo} className="loader" alt="loader" />
    </div>
  );
};

export default Loader;
